<template>
       <div 
            :class="['download-container', { expanded: isExpanded }]"
            @mouseenter="toggleExpand(true)"
            @mouseleave="toggleExpand(false)"
            @click="downloadPDF"
        >
            <download-icon size="1.3x" class="custom-class icone-download"></download-icon> 
            <span class="download-text">Download</span>
        </div>
</template>

<script>
import {DownloadIcon} from 'vue-feather-icons';


export default {
    components: {
        DownloadIcon
    },

    props: {
        arquivo: {
            type: String,
            required: true
        },
        nomeArquivo: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isExpanded: false,
        }
    },

    methods: {
        toggleExpand(expand) {
            this.isExpanded = expand;
        },
        downloadPDF() {
            const link = document.createElement('a');
            link.href = this.$props.arquivo; 
            link.download = this.$props.nomeArquivo;
            link.click();
        },
    }
}
</script>
<style scoped>
    .download-container{
        cursor: pointer;
        position: fixed; 
        right: 0;
        top:70px;
        width: 60px;
        height: 30px;
        background-color:#F8F8F8; 
        z-index: 9999;
        border-radius: 20px 0 0 20px;
        box-shadow: 0 0 3px rgba(0,0,0,0.2);
    }
    .download-container.expanded {
        width: 120px;
    }

    .download-text {
        white-space: nowrap;
        opacity: 0; 
    }

    .download-container.expanded .download-text {
        opacity: 1;
    }

    .icone-download{
        margin: 6px 9px;
    }
</style>