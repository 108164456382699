<template>
    <div>
        <DownloadPoliticaPrivacidade v-if="arquivoPdf != null" :arquivo="arquivoPdf" :nomeArquivo="nomeArquivo"/>
        <div v-if="showPdf">
            <vue-pdf-app class="alturaPdf" :pdf="arquivoPdf" :config="config"></vue-pdf-app>
        </div>
        <div v-else>
            <div class="mt-2 mt-md-5 mx-auto text-center">
                <b-spinner variant="custom" label="Loading..." v-if="loadingPdf"></b-spinner>
            </div>
            <div v-html="this.msgShowPdfFalse"></div>
        </div>
    </div>
</template>

<script>

import {BSpinner} from 'bootstrap-vue';
import { $tipos } from '@/custom-enum/tipos-enum.js';
import DownloadPoliticaPrivacidade from '@/views/pages/politica-privacidade/DownloadPoliticaPrivacidade.vue';

export default {
    components: {
        BSpinner,
        DownloadPoliticaPrivacidade
    },
    data() {
        return {
            loadingPdf: true,
            showPdf: false,
            nomeArquivo: 'Política de Privacidade - Saúde Mental.pdf',
            msgShowPdfFalse: '',
            arquivoPdf: null,
            config: {
                toolbar: false
            },
        }
    },
    beforeMount() {
        this.loadPdf();
    },
    methods: {
        startLoad(){
            this.msgShowPdfFalse = '<div class="font-medium-5 text-center">Aguarde, estamos buscando<br>o documento de Política e Privacidade...</div>'
            this.loadingPdf = true;
        },
        stopLoad(){
            this.msgShowPdfFalse = '';
            this.loadingPdf = false;
        },
        loadPdf() {
            this.startLoad()
            this.$http.get(this.$api.Documento,{params: {'id_tipo_documento':$tipos.documentos.saudeMental}, responseType: "blob"})
                .then((res) => {
                    this.showPdf = true;
                    let blob = new Blob([res.data]);
                    this.arquivoPdf = URL.createObjectURL(blob);
                    this.stopLoad()
                })
                .catch(() => {
                    this.loadingPdf = false;
                    this.msgShowPdfFalse = '<div class="font-large-2 text-center text-danger font-weight-bolder">Atenção!</div><div class="font-medium-3 text-center">Ocorreu um erro ao buscar o documento</div>';
                });
        }
    }
}
</script>
<style scoped>
.alturaPdf{
    height: 88vh;
}
.pdf-app.light {
    --pdf-app-background-color: #F8F8F8;
}
</style>
